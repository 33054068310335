import 'bootstrap';
// import './calendar.mjs';

window.mobileOpticaOptions = {
  form: {},
};

jQuery(document).ready(($) => {
  const initSelect = ($select, title) => {
    const $firstOption = $select.children().eq(0);
    $firstOption.val(undefined);
    $firstOption.attr('disabled', '');
    if (title) {
      $firstOption.text(title);
    }
    $select.on('change', (e) => {
      const $el = $(e.target);
      const value = e.target.value;
      console.log('$el', $el);
      if (value) {
        $el.addClass('selected');
      } else {
        $el.removeClass('selected');
      }
    });
  };

  const submitBooking = () => {
    const { bk_type, formdata, wpdev_active_locale } =
      window.mobileOpticaOptions.form;
    // Cpatch  verify
    const captcha = document.getElementById(
      'wpdev_captcha_challenge_' + bk_type
    );
    //Disable Submit button
    jQuery('#booking_form_div' + bk_type + ' input[type="button"]').prop(
      'disabled',
      true
    );
    jQuery('#booking_form_div' + bk_type + ' button').prop('disabled', true); //FixIn: 8.5.2.7
    if (captcha != null)
      form_submit_send(
        bk_type,
        formdata,
        captcha.value,
        document.getElementById('captcha_input' + bk_type).value,
        wpdev_active_locale
      );
    else form_submit_send(bk_type, formdata, '', '', wpdev_active_locale);
    return;
  };

  const initBooking = () => {
    const $bookingContainer = $('.booking-container');
    const $booking = $bookingContainer.children('.booking');
    const $bookingBtn = $bookingContainer.find('.btn-booking');
    $bookingBtn.on('click', () => {
      $booking.css('display', 'none');
      $booking.removeClass('d-none');
      $bookingBtn.hide();
      $booking.slideDown(1000);
      window.makeScroll('.booking');
    });

    const $requiredFields = $('.wpdev-validates-as-required[type="text"]');

    $requiredFields.each((i, v) => {
      const $this = $(v);
      const placeholder = $this.attr('placeholder');
      $this.attr('placeholder', `${placeholder}*`);
    });

    const $isikukood = $('[name^="isikukood"]');
    $isikukood.attr('type', 'number');
    $isikukood.attr('minLength', '11');
    $isikukood.attr('maxLength', '11');
  };

  const updateAcceptModal = ($acceptModal) => {
    const $formData = $acceptModal.find('.form-data');
    const $modalInfo = $acceptModal.find('.modal-info');
    const $contactInfo = $('.col--contact-info');
    const $booking = $('.booking');
    let formDataHtml = '<table>';
    const formData = {};
    $contactInfo.find('input[type="text"]').each((i, e) => {
      const $this = $(e);
      const name = $this.attr('name');
      const val = $this.val();
      formData[name] = val;
      formDataHtml += `<tr><th><strong>${$this.attr(
        'placeholder'
      )}:</strong></th><td>${$this.val()}</td></tr>`;
    });
    formDataHtml += '</table>';

    // let selectedDate = null;

    // $booking
    //   .find('.booking_form_div')
    //   .on('date_selected', (event, bk_type, date) => {
    //     selectedDate = date;
    //   });

    const $timeSelector = $booking.find('[name="rangetime5"]');
    const $dateSelector = $booking.find('[name="date_booking5"]');
    const $city = $booking.find('[name="city5"]');

    console.log('formData', formData);
    $modalInfo.find('.booking-city').html($city.val());
    $modalInfo.find('.booking-date').html($dateSelector.val());
    $modalInfo.find('.booking-time').html($timeSelector.val());
    $modalInfo.find('.booking-address').html(formData['aadress5']);

    $formData.html(formDataHtml);
  };

  const initModals = () => {
    const $acceptModal = $('#acceptModal');
    const $successModal = $('#successModal');
    const $businessModal = $('#businessModal');
    $acceptModal.modal({});
    $successModal.modal({});
    $businessModal.modal({});
    $acceptModal.on('show.bs.modal', () => {
      updateAcceptModal($acceptModal);
    });

    $acceptModal.find('.btn-submit').on('click', () => {
      submitBooking($acceptModal.data('bk_type'));
    });

    $('.booking-container').on('booked', () => {
      $acceptModal.modal('hide');
      $successModal.modal('show');
    });

    $('[href="#btn-business"]').on('click', (e) => {
      e.preventDefault();
      $businessModal.modal('show');
    });
  };

  const onChange = (el) => {
    $('.wpcf7-list-item').removeClass('checked');
    $(el).closest('.wpcf7-list-item').addClass('checked');
  };

  const $feedbackForm = $('.feedback form');
  const $radioInput = $feedbackForm.find('.wpcf7-radio');

  onChange($radioInput.find('input:checked'));

  $radioInput.find('input').on('change', (e) => {
    onChange(e.target);
  });

  const $booking = $('.booking');
  // const $city = $booking.find('[name="city5"]');
  // initSelect($city);
  const $calAndTime = $('.col--calender-and-time');
  const $contactInfo = $('.col--contact-info');

  $calAndTime.addClass('active');
  $contactInfo.addClass('active');

  // $city.on('change', (e) => {
  //   const value = e.target.value;
  //   if (value) {
  //     $calAndTime.addClass('active');
  //     $contactInfo.addClass('active');
  //   } else {
  //     $calAndTime.removeClass('active');
  //     $contactInfo.removeClass('active');
  //   }
  //   console.log('value', value);
  // });

  initBooking();
  // initSelect($('.come-to-city [name="city"]'), 'Vali linn');
  initModals();
});
